import React from 'react';
import './careerspage.css';

// Images
import doctorJob from '../images/careerimages/doctorJob.png';
import nurseJob from '../images/careerimages/nurseJob.png';
import audiologist from '../images/careerimages/audiologist.png';
import optometry from '../images/careerimages/optometry.png';

const CareersPage = () => {
    const [activeItem, setActiveItem] = React.useState('');

    const openJD = (job) => () => {
        setActiveItem(job);

        const element = document.getElementById('JD');
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        }
    }

  return (
    <div style={{marginBottom: activeItem==='' ? '10vh': '', padding:'10vh', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <h1>Interested in joining our team?</h1>
      

        <div className='career-cards'>
            
            <div className='career-card' onClick={openJD('doctor')}>
                <h2>Medical Doctor</h2>

                <div className='image-and-text'>
                    <img src={doctorJob} alt="doctor" />
                    <h3 className='text'>Find out more</h3>
                </div>
                
            </div>

            <div className='career-card' onClick={openJD('nurse')}>
                <h2>Nurse</h2>

                <div className='image-and-text'>
                    <img src={nurseJob} alt="nurse" />
                    <h3 className='text'>Find out more</h3>
                </div>

            </div>

            <div className='career-card' onClick={openJD('audiologist')}>
                <h2>Audiologist</h2>

                <div className='image-and-text'>
                    <img src={audiologist} alt="audiologist" />
                    <h3 className='text'>Find out more</h3>
                </div>
            </div>

            <div className='career-card' onClick={openJD('optometrist')}>
                <h2>Optometrist</h2>

                <div className='image-and-text'>
                    <img src={optometry} alt="optometry" />
                    <h3 id='JD' className='text'>Find out more</h3>
                </div>
            </div>

        </div>


        <div >
            {activeItem === 'doctor' &&
                <div className='job-description'>
                    <h2>Details for Doctor Position</h2>
                    <p>Using large-scale, multi-disciplinary mobile clinics, we deliver optometry, audiology, primary healthcare, and oral healthcare services directly to schools and communities at large ensuring convenience, quality healthcare, provision of medical equipment and staff who sincerely care.</p>

                    <p>
                    We are seeking a professional medical doctor who is patient centered and passionate about providing Primary Health Care in conjunction with the latest telemedicine technology. This opportunity allows for remote work- but passion is key because that has to transcend over a remote consultation! This position is for a qualified medical doctor, registered Health Professional Council of SA. Patients will be both children at schools and adults, whom will all be attended to within the mobile clinic.
                    </p>

                    <p>
                    The Doctor will be serving most of their hours REMOTELY, using our telemedicine infrastructure. However, hours IN OFFICE and on MOBILE HEALTH CLINICS are required. The doctor will work remotely with the attending nurse who is stationed on the Mobile Clinic to provide a holistic assessment and management of the patient that has been identified as needing a doctor’s care. This includes sending prescriptions, referrals, and providing an appropriate counselling.
                    </p>

                    <p><strong>The role description of the doctor:</strong></p>
                    <ul>
                        <li><p>Be able to use telemedicine healthcare services and medical software.</p></li>
                        <li><p>Ensure referred patients are recorded accurately, including reasons for referral and where to, so a follow up can be done when needed.</p></li>
                        <li><p>Able to use the onboard software to capture all clinical findings and generate referrals during the field visit.</p></li>
                        <li><p>Ensure that each patient is treated in a friendly, professional manner always upholding the highest standards of the profession the individual must be respectful of cultural differences and treat each other as a valued individual.</p></li>
                        <li><p>Diagnose disease / problem.</p></li>
                        <li><p>Create treatment plans to maintain and promote health.</p></li>
                        <li><p>Administer medication if onsite a primary healthcare facility.</p></li>
                        <li><p>Provide prescriptions and referral letters.</p></li>
                        <li><p>Perform corrective medical interventions.</p></li>
                        <li><p>Report any malfunction of equipment immediately by reporting it to the operator or project manager.</p></li>
                        <li><p>Be able to actively problem solve software malfunction and promptly report it to the operator and IT specialist.</p></li>
                        <li><p>Submit at least one good news story / case summary per week on patient interaction and feedback.</p></li>
                    </ul>
                </div>
            }

            {activeItem === 'nurse' &&
                <div className='job-description'>
                    <h2>Details for Professional Nurse Position</h2>
                    <p>At Guud Health, we recognize the need for mobility to provide essential services to the masses. Today, there are many people living without access to the most basic public services, such as healthcare services that could improve their quality of life. We have developed a solution in terms of a mobile clinic and strategy that provide the tools and support to government and private corporations to enable them to make sustainable impact in under-served communities of South Africa. Using large-scale, multi-disciplinary mobile clinics, we deliver, telemedicine doctors, telemedicine optometry and optometry assistance services, at large ensuring convenience, quality healthcare, provision of medical equipment and staff who sincerely care. We are looking for someone who is committed to making a difference and is passionate on creating an impact by delivering healthcare to community doorsteps through our mobile clinics. The role has the potential to develop into a team leader and /or training position.</p>

                    <p><strong>The role description of the professional nurse:</strong></p>
                    <ul>
                        <li><p>Start the day by cleaning and sanitizing primary healthcare workstation.</p></li>
                        <li><p>Whenever at a new location the team is to take a photo with signage of the location.</p></li>
                        <li><p>Prior to start of the project it is the responsibility of the practitioner to test all equipment and be familiar with the specific models available.</p></li>
                        <li><p>Performing health assessments on a mobile health and generate referrals if necessary.</p></li>
                        <li><p>Create a list of referred patients who will need further observation by the medical doctor and arrange appointments for medical doctor who will be present via telemedicine.</p></li>
                        <li><p>Ensure referred patients are recorded accurately, including reasons for referral and where to, so a follow up can be done when needed.</p></li>
                        <li><p>Able to use the onboard software to capture all clinical findings and generate referrals during the field visit.</p></li>
                        <li><p>Ensure that each patient is treated in a friendly, professional manner upholding the highest standards of the profession. At all times the individual must be respectful of cultural differences and treat each other as a valued individual.</p></li>
                        <li><p>Manage a dispensary, take stock on Mondays and Thursdays. Submit this to the project manager.</p></li>
                        <li><p>If there is any dispensing of medication, ensure all medicine dispensing labels are complete and a separate written copy is kept of every prescription issued. Submit a list to the project manager of medication dispensed.</p></li>
                        <li><p>Acting as the Health Care Waste Officer, we have a waste policy to follow.</p></li>
                        <li><p>Able to complete a target of routine PHC examinations on patients visiting the mobile health unit per day.</p></li>
                        <li><p>Able to do finger prick testing on adults, interpret blood glucose and cholesterol measurements and take blood pressure.</p></li>
                        <li><p>Report any malfunction of equipment immediately by reporting it to the operator or project manager.</p></li>
                        <li><p>Ensure each referral form is scanned into the Fyne forms in the event that a patient needs to be followed up on.</p></li>
                        <li><p>Submitting at least one good news story / case summary per week on patient interaction and feedback.</p></li>
                        <li><p>Submit daily report on day’s activities and at least 2 photos of the day as per daily form.</p></li>
                        <li><p>Ensure work station and equipment is sanitized with disinfectant at the end of every day.</p></li>
                    </ul>

                    <p><strong>Details of position:</strong></p>

                    <ul>
                        <li><p>Salary is market related based on experience.</p></li>
                        <li><p>Projects are based in all provinces.</p></li>
                        <li><p>Reliably commute or planning to relocate before starting work (Required).</p></li>
                        <li><p>Nursing Council registration (Required).</p></li>
                        <li><p>Willingness to travel: 50% (Preferred).</p></li>
                    </ul>
                </div>
            }

            {activeItem === 'audiologist' &&
                <div className='job-description'>
                    <h2>Details for Audiologist Position</h2>
                    <p>We are seeking a dedicated audiologist who is committed to effecting positive change in delivering healthcare directly to communities through our mobile clinics. This role specifically pertains to a qualified audiologist, duly registered with the Health Professions Council of South Africa (HPCSA).</p>

                    <p>You will be responsible for providing audiological services to a diverse patient demographic, including school children and adults. Currently, all consultations will be conducted within the mobile clinic setting, ensuring accessibility and convenience for our community-based healthcare initiatives.</p>

                    <p>Additionally, you will assist in the structuring and development of remote audiology testing and screening, allowing remote audiology services. This will create the opportunity for the ability to work away from the mobile clinic and from the comfort of your home.</p>

                    <p><strong>The role description of the audiologist while in Mobile Clinic:</strong></p>

                    <ul>
                        <li><p>Start the day by cleaning and sanitizing workstation.</p></li>
                        <li><p>Delivering audiology services on a mobile health facility visiting government schools and other community centers, using KuduWave Audiometer or equivalent.</p></li>
                        <li><p>Prior to start of the project test all equipment and be familiar with the specific model available.</p></li>
                        <li><p>Able to use the onboard software to capture all clinical findings, finalize prescriptions and generate referrals during field visit.</p></li>
                        <li><p>Treating each patient in a friendly, professional manner upholding the highest standards of the profession. At all times the individual must be respectful of cultural differences and treat each other as a valued individual.</p></li>
                        <li><p>Able to complete a target of routine examinations and hearing tests on patients visiting the mobile health unit per day.</p></li>
                        <li><p>Diagnose hearing problems, interpret test results, prescribe corrective measures or refer for further diagnostics tests and/or treatment.</p></li>
                        <li><p>Counsel patients about hearing health and the possible need for treatment/management.</p></li>
                        <li><p>Assess the candidacy of persons with hearing loss for hearing aids and provide referrals for these specific patients.</p></li>
                        <li><p>Screen speech-language and other factors affecting communication function for the purposes of audiologic evaluation and/or initial identification of individuals with other communication disorders.</p></li>
                        <li><p>Screen vestibular disorders and manage accordingly.</p></li>
                        <li><p>Record referred patients accurately, including reasons for referral and where to, so a follow up can be done when needed.</p></li>
                        <li><p>Report any malfunction of the equipment immediately by reporting it to the operator or project manager.</p></li>
                        <li><p>When based at a school train a minimum of 2 educators at each school on doing basic screening in the classroom.</p></li>
                        <li><p>Ensure that consent forms are filed in a date orderly system in the files provided.</p></li>
                        <li><p>Ensure each referral form is scanned into the Fyne forms in the event that a patient needs to be followed up on.</p></li>
                        <li><p>Submitting at least one good news story / case summary per week on patient interaction and feedback.</p></li>
                        <li><p>Submit daily report on day’s activities and at least 2 photos of the day as per daily form.</p></li>
                        <li><p>Create Audiology awareness campaigns and initiatives.</p></li>
                        <li><p>Ensure workstation and equipment is sanitized with disinfectant at the end of every day.</p></li>
                    </ul>

                    <p><strong>Details of position</strong></p>

                    <ul>
                        <li><p>Salary is market related based on experience.</p></li>
                        <li><p>Current positions are in Kuruman - Northern Cape, Eastern Cape and Thabazimbi - Limpopo, Century City - Cape Town.</p></li>
                        <p>(When based outside of these locations a living out allowance will be provided - accommodation and meal allowance)</p>
                    </ul>
                </div>
            }

            {activeItem === 'optometrist' &&
                <div className='job-description'>
                    <h2>Details for Optometrist Position</h2>
                    <p>At Guud Health, we recognise the need for mobility to provide essential services to the masses. Today, there are many people living without access to the most basic public services, such as healthcare services that could improve their quality of life. We have developed a solution in terms of a mobile clinic and strategy that provide the tools and support to government and private corporations to enable them to make sustainable impact in under-served communities of South Africa. Using large-scale, multi-disciplinary mobile clinics, we deliver, telemedicine doctors and nurses, telemedicine optometry and optometry assistance services, at large ensuring convenience, quality healthcare, provision of medical equipment and staff who sincerely care. We are looking for someone who is committed to making a difference and is passionate on creating an impact by delivering healthcare to community doorsteps through our mobile clinics. This position is for a qualified optometrist, registered with HPCSA. Patients will be both children at schools and adults, whom will all be attended to within the mobile clinic.</p>

                    <p><strong>The role description of the optometrist:</strong></p>

                    <ul>
                        <li><p>Start the day by cleaning and sanitizing optometric workstation.</p></li>
                        <li><p>Delivering optometry services on a mobile health facility visiting government schools and other community centres.</p></li>
                        <li><p>Prior to start of the project it is the responsibility of the optometrist to test all equipment and be familiar with the specific model available.</p></li>
                        <li><p>Able to use the onboard software to capture all clinical findings, finalize prescriptions and generate referrals during field visit.</p></li>
                        <li><p>Ensure that each patient is treated in a friendly, professional manner upholding the highest standards of the profession. At all times the individual must be respectful of cultural differences and treat each other as a valued individual.</p></li>
                        <li><p>Able to complete a target of routine eye examination and vision tests on patients visiting the mobile health unit per day.</p></li>
                        <li><p>Diagnose sight problems, prescribe corrective lenses, or refer for further diagnostics tests and/or treatment.</p></li>
                        <li><p>Manage a dispensary, take stock on Mondays and Thursdays. Submit this to the project manager.</p></li>
                        <li><p>Send the list of spectacles prescribed to the project manager.</p></li>
                        <li><p>Ensure referred patients are recorded accurately, including reasons for referral and where to, so a follow up can be done when needed.</p></li>
                        <li><p>Report any malfunction of the optometry equipment immediately by reporting it to the operator or project manager.</p></li>
                        <li><p>Ensure that consent forms are filed in a date orderly system in the files provided.</p></li>
                        <li><p>Ensure each referral form is scanned into the Fyne forms if a patient needs to be followed up on.</p></li>
                        <li><p>Submitting at least one good news story / case summary per week on patient interaction and feedback.</p></li>
                        <li><p>Submit daily report on day’s activities and at least 2 photos of the day as per daily form.</p></li>
                        <li><p>Ensure workstation and equipment is sanitized with disinfectant at the end of every day.</p></li>
                    </ul>

                    <p><strong>Details of position</strong></p>

                    <ul>
                        <li><p>Salary is market related based on experience.</p></li>
                        <li><p>Projects are based in all provinces.</p></li>
                        <li><p>Reliably commute or planning to relocate before starting work (Required)</p></li>
                        <li><p>HPCSA registration (Required).</p></li>
                        <li><p>Willingness to travel: 50% (Preferred).</p></li>
                    </ul>
                </div>

                
            }

        </div>  

        <p style={{marginTop:'3vh'}}>If interested, please provide CV and qualifications to careers@guudhealth </p>

    </div>
  )
};

export default CareersPage;
