import React from 'react';
import './homepage.css';

// Images
import Shayela5 from '../images/Shayela5.png';
import Nurses from '../images/nurses.png';

// clients
import Guud from '../images/clientlogos/guudLogo.png';
import RAF from '../images/clientlogos/RAFLogo.png';
import SIOC from '../images/clientlogos/SIOCLogo.png';

import { Button } from 'semantic-ui-react';

const HomePage = () => {
  return (
    <div>
        <img className='bus-image' style={{padding:'0vh'}} src={Shayela5} alt="Shayela5" />
        <div style={{paddingLeft:'5vh', paddingRight:'5vh'}}>
            <div style={{margin:'3vh'}}>
                <div className='section about-us-section' id='about-us'>
                    <img className='regular-image' src={Nurses} alt="Nurses" />
                    <div className='about-us-content'>
                        <h1>About Us</h1>
                        <p>Our core expertise focuses on Healthcare Technology, Medical Supplies and Medical Staffing solutions supporting mobile health clinic projects nationwide with a solid 13 year track record.  Guud Health is a leading healthcare service provider within the South African mobility industry supporting both private organisations and rural development areas. Our Staffing Solutions Support services includes Primary Healthcare - Medical Practitioners, Optometrists, Optical Dispensers,  Audiologists and Dentistry. </p>

                        <h1>Our Mission</h1>
                        <p>Guud Health is dedicated to revolutionizing healthcare delivery across South Africa by leveraging innovative technology and data-driven insights. Our mission is to make healthcare accessible, convenient, and comprehensive, ensuring that every individual receives high-quality care, anytime and anywhere. We are committed to enhancing patient outcomes through continuous innovation, holistic collaborative care, and empowering healthcare professionals.</p>

                        <p>Our goal is to blend technology, compassion, and expertise to create a robust and efficient healthcare ecosystem.</p>

                        <p>We believe that healthcare should be accessible to everyone, regardless of their location or circumstances. Our mobile platform ensures that patients can receive high-quality care anytime, anywhere. Whether it’s a routine check-up, urgent medical advice, or ongoing treatment, Driving Health brings healthcare to your doorstep and fingertips, promoting respect for persons and patient autonomy by ensuring patient access to care and informed decision-making.</p>
                    </div>
                </div>

                <div className='section our-solutions-section'  id='our-solutions'>
                    <h1>Our Solutions</h1>
                    <div className='solutions-cards'>
                        <div className='solutions-card'>
                            <h2>Healthcare Technology</h2>
                            <p>We offer cutting-edge software and health technology, facilitating healthcare delivery anytime, anywhere via the use of tele-medicine, improving accessibility to healthcare nationwide.</p>
                        </div>
                        <div className='solutions-card'>
                            <h2>Medical Supplies</h2>
                            <p>We supply all the necessary medical equipment and consumables, empowering our clients to deliver excellent quality services consistently.</p>
                        </div>
                        <div className='solutions-card'>
                            <h2>Staffing Solution</h2>
                            <p>Our commitment extends to hiring and training top-tier medical professionals nationwide. Our diverse teams encompass optometrists, medical doctors, radiologists, occupational health specialists, nurses, audiologists, optometry dispensers and more.</p>
                        </div>
                    </div>

                    <p>Find out more about our solutions currently provided to a commercial Drivers Wellness Programme in South Africa.</p>
                    <Button primary as="a" href="https://guudmobility.com/our-solution/" target="_blank" rel="noopener noreferrer">
                        The Shayela Programme
                    </Button>
                </div>
            </div>

            <div className='section clients-section' id='clients'>
                    <div style={{marginLeft:'3vh'}}>
                        <h1>Clients</h1>
                    </div>
                    
                    <div className='clients-logos'>
                        <a href='https://guudmobility.com/' target='_blank' rel='noopener noreferrer'>
                        <img src={Guud} alt="Guud" />
                        </a>
                        <a href='https://www.raf.co.za/Pages/Default.aspx' target='_blank' rel='noopener noreferrer'>
                        <img src={RAF} alt="RAF" />
                        </a>
                        <a href='https://www.sioc-cdt.co.za/re-a-fola-health-promotion-project/' target='_blank' rel='noopener noreferrer'>
                        <img src={SIOC} alt="SIOC" />
                        </a>
                    </div>
                </div>
        </div> 
    </div>
  )
};

export default HomePage;
