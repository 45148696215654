import LogoSquare from '../images/GHSquare.jpg';
import { Icon } from 'semantic-ui-react';
import './contactus.css';

export default function ContactUs() {
  return (
    <div className='contact-us-section'>
            <img className='small-image' src={LogoSquare} alt="DHSmall3" />
            <div>
                <h1>Contact Us</h1>
                <p> <Icon name='pin'/>Block F1, Mayfair Square, Century Way, Century City, Cape Town</p>
                <p> <Icon name='phone'/>021 300 6408</p>
                <p> <Icon name='mail'/> careers@guudhealth </p>
            </div>
    </div>
    );
}