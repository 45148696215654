import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './App.css';

import LogoLong from './images/GHLong.jpg';

// Pages
import HomePage from './pages/HomePage';
import CareersPage from './pages/CareersPage';

// Components
import ContactUs from './components/ContactUs';

// Semantic UI
import 'semantic-ui-css/semantic.min.css';
import { Menu } from 'semantic-ui-react';

// Navigation Menu Component
const NavMenu = () => {
  const location = useLocation();

  const handleHomeScroll = (id) => {
    const element = document.getElementById(id);
    console.log(id);
    if (location.pathname === '/careers' ){
      // Redirect to home page
      window.location.href = '/';
    }

    if (element) {
      const yOffset = -200; // Adjust this value to control how far above the element you want to scroll
      const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
    
  };

  return (
    <Menu className="ui massive secondary right menu" stackable>
      <Menu.Item
        as={Link}
        to="/"
        name="Home"
        active={location.pathname === '/'}
      />
      <Menu.Item
        name="About Us"
        onClick={() => handleHomeScroll('about-us')}
      />
      <Menu.Item
        name="Our Solutions"
        onClick={() => handleHomeScroll('our-solutions')}
      />
      <Menu.Item
        name="Clients"
        onClick={() => handleHomeScroll('clients')}
      />
      <Menu.Item
        as={Link}
        to="/careers"
        name="Careers"
        active={location.pathname === '/careers'}
      />
    </Menu>
  );
};

const App = () => {
  return (
    <Router>
      <div className='logo-and-menu'>
        <div className='logo-and-slogan'>
          <img src={LogoLong} alt="Logo" />
          <p><i>Driving Health Further</i></p>
        </div>
        <NavMenu />
      </div>
      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/careers" element={<CareersPage />} />
      </Routes>

      <ContactUs />
    </Router>
  );
};

export default App;
